<template>
  <div>
    <div class="link-head">
      <router-link class="link-head-title" :to="goBackUrl()">
        <i class="title-arrow el-icon-arrow-left"></i>
        <h3 class="title-text">
          {{ infoData.type_name + "环节" }}
        </h3>
        <i class="title-icon" :class="`title-icon-${this.infoData.type}`"></i>
      </router-link>
    </div>

    <div class="title">{{ infoData.name }}</div>

    <div class="one_box">
      <span class="link-date">
        环节时间：{{ infoData.start_date }} 至 {{ infoData.end_date }}
      </span>

      <el-popover
        placement="top"
        width=""
        trigger="click"
        v-if="isEdit && $store.state.activity_isEnable"
      >
        <el-date-picker
          v-model="activityInfoTime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="changeTImeEvent"
        >
        </el-date-picker>
        <div class="change_time_div_btn" slot="reference" @click="openTImeSelectBox">
          更改时间
        </div>
      </el-popover>
      <el-dialog title="" :visible.sync="time_visible" width="500px" top="20vh">
        <div style="text-align: center">
          <el-button
            type="primary"
            @click="handleSync(2)"
            v-if="activityInfoData.audit_status == 1"
          >
            确定
          </el-button>
          <template v-else>
            <el-button type="primary" @click="handleSync(1)">
              确认并同步活动状态
            </el-button>
            <el-button type="primary" @click="handleSync(2)">不同步活动状态</el-button>
          </template>
          <el-button @click="time_visible = false">取消</el-button>
        </div>
      </el-dialog>

      <div v-if="infoData.status == 1" class="conduct nostart">未开始</div>
      <div v-if="infoData.status == 2" class="conduct going">进行中</div>
      <div v-if="infoData.status == 3" class="conduct end">已结束</div>
      <EndLink
        :id="id"
        :is_from="is_from"
        :status="infoData.status"
        @success="endLinkSuccess"
        v-if="isEdit && $store.state.activity_isEnable"
      ></EndLink>
    </div>
    <div class="two">
      <span>环节类型：{{ infoData.type_name }}{{infoData.aaa}}</span>
      <span>开展方式：{{ infoData.implement_type == "1" ? "线上" : "线下" }}</span>
      <span class="join-record" @click="joinRecord()">
        参与人数:{{ infoData.user_count }}人
      </span>
    </div>
    <div class="title-box">环节描述</div>
    <div class="link-describe" v-if="infoData.description" v-html="format(infoData.description)"></div>

    <MyDialog
      :open="joinRecordOpen"
      title="活动环节参与人"
      @onClose="joinRecordOpen = false"
      width="1120px"
    >
      <ListOne :typeVal="4" :list="joinRecordList"></ListOne>
    </MyDialog>
  </div>
</template>

<script>
import { queryString } from "@/utils/utils.js";
import * as researchApi from "@/api/researchApi.js";
import ListOne from "@/components/CommonList/ListOne.vue";
import EndLink from "../components/endLink/endLink.vue";
export default {
  components: { ListOne, EndLink },
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: () => {},
    },
    //详情数据
    infoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      //活动Id
      activityId: "",
      //环节id
      id: "",
      //1教研活动详情，2四名工作室活动详情
      is_from: 1,
      activityInfoTime: [],
      time_reqObj: {},
      time_visible: false,
      joinRecordOpen: false,
      joinRecordList: [],
    };
  },
  methods: {
    // 点击更改时间
    openTImeSelectBox() {
      this.activityInfoTime = [
        `${this.infoData.start_date}`,
        `${this.infoData.end_date}`,
      ];
    },
    // 同步迟环节
    async handleSync(num) {
      try {
        let time_reqObj = this.time_reqObj;
        time_reqObj["is_sync_status"] = num;
        time_reqObj["is_personid"] = this.is_from;
        let res_1 = await researchApi.get_activity_activitylink_update_start_end_date(
          time_reqObj
        );
        this.time_visible = false;
        if (res_1.data.data.status) {
          this.$set(this.infoData, "status", res_1.data.data.status);
          this.$set(this.infoData, "start_date", res_1.data.data.start_date);
          this.$set(this.infoData, "end_date", res_1.data.data.end_date);
        }
        this.$message.success("操作成功");
        this.$parent.$parent.getList();
      } catch (error) {
        this.time_visible = false;
      }
    },
    // 确定更改l环节时间
    changeTImeEvent(e) {
      if (e) {
        let time_reqObj = {
          id: this.id,
          start_date: e[0],
          end_date: e[1],
        };
        this.time_visible = true;
        this.time_reqObj = time_reqObj;
      }
    },
    //结束环节
    endLinkSuccess(status) {
      this.$set(this.infoData, "status", status);
      this.$parent.$parent.getList();
    },
    goBackUrl() {
      let query = {
        activityId: this.activityId,
        is_from: this.is_from,
      };
      return `/basicinfo${queryString(query)}`;
    },
    //参与人数
    joinRecord() {
      let params = {
        activityId: this.activityId,
        is_personid: this.is_from,
        linkId: this.id,
        page: 1,
        limit: 999999,
      };
      this.$axios
        .get("/activityUserCount/UserActivityLinkList", { params })
        .then((res) => {
          this.joinRecordList = res.data.data.data;
        });
      this.joinRecordOpen = true;
    },
    format(html) {
      var GRT = [
        // img 样式
        ['img', "max-width:100%;height:auto;"],
      ];
      for (let i = 0; i < GRT.length; i++) {
        html = html.replace(new RegExp('<' + GRT[i][0] + '>|<' + GRT[i][0] + ' (.*?)>', 'gi'), function (word) {
          // 分析 dom 上是否带有 style=""
          if (word.indexOf('style=') != -1) {
            var regIn = new RegExp('<' + GRT[i][0] + '(.*?)style="(.*?)"(.*?)(/?)>', 'gi');
            return word.replace(regIn, '<' + GRT[i][0] + '$1style="$2 ' + GRT[i][1] + '"$3$4>');
          } else {
            var regIn = new RegExp('<' + GRT[i][0] + '(.*?)(/?)>', 'gi');
            return word.replace(regIn, '<' + GRT[i][0] + '$1 style="' + GRT[i][1] + '$2">');
          }
        });
      }
      return html;
    },
  },
  mounted() {},
  created() {
    this.activityId = this.$route.query.activityId;
    this.id = this.$route.query.id;
    this.is_from = this.$route.query.is_from || 1;
  },
  computed: {
    //判断是否能编辑
    isEdit() {
      let uid = localStorage.getItem("id");
      if (uid == this.infoData.user_id) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.one_box,
.two,
.link-describe {
  padding-left: 30px;
  padding-right: 30px;
}
.link-describe {
  display: inline-block;
}
.title-box {
  margin-left: 30px;
  margin-right: 30px;
}
.join-record {
  cursor: pointer;
}
</style>
