import { render, staticRenderFns } from "./activityLinkInfo.vue?vue&type=template&id=718b0115&scoped=true"
import script from "./activityLinkInfo.vue?vue&type=script&lang=js"
export * from "./activityLinkInfo.vue?vue&type=script&lang=js"
import style0 from "./activityLinkInfo.vue?vue&type=style&index=0&id=718b0115&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718b0115",
  null
  
)

export default component.exports