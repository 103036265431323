<template>
  <div class="discussbrowsing study-active-introduce">
    <ActivityLinkInfo
      :activityInfoData="activityInfoData"
      :infoData="resourceData"
    ></ActivityLinkInfo>
    <!-- <div class="hengxian"></div> -->
    <div class="main_content">
      <div class="title-box">讨论（{{ commentTotal }}）</div>
      <!-- <div class="five cursor" v-if="textActive == true" @click="showText">
        <span>查看全部</span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="five cursor" v-else-if="textActive == false" @click="showText">
        <span>收起</span>
        <i class="el-icon-arrow-up"></i>
      </div> -->

      <div v-if="resourceData.implement_type == '1'">
        <div class="seven" v-if="$store.state.isLogin">
          <div class="seven_left">
            <div class="images">
              <!-- <img src="@/assets/images/avator.png" /> -->
              <img :src="photo_url" />
            </div>
            <div class="left_word">{{ username }}</div>
          </div>
          <div class="seven_right">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="写下你的评论..."
              v-model="myContent"
              resize="none"
            ></el-input>
            <div
              class="release cursor"
              @click="releaseComment"
              v-if="$store.state.activity_isJoin && $store.state.activity_isEnable"
            >
              发布
            </div>
          </div>
        </div>

        <!-- 评论列表组件区域 -->
        <div class="new_comment">
          <new-comment
            :activity_id="activityId"
            :link_id="id"
            :random="random"
            :module_type="is_from == 1 ? 1 : 6"
            typeName="讨论"
            @onTotal="onCommentTotal"
          ></new-comment>
        </div>
      </div>

      <!-- 线下区域 -->
      <UnderLineDetail
        :is_from="+is_from"
        :resourceData="resourceData"
        v-else-if="resourceData.implement_type == '2'"
        @getResourceData="getResourceData"
        :activityId="+activityId"
        :linkId="+id"
      />
    </div>
  </div>
</template>

<script>
import UnderLineDetail from "../components/underlineDetail/index.vue";
import ActivityLinkInfo from "../components/activityLinkInfo.vue";
export default {
  components: {
    UnderLineDetail,
    ActivityLinkInfo,
  },
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 活动Id
      activityId: "",
      // 环节id
      id: "",
      // 当前登录的用户名
      username: "",
      // 当前登录的用户id
      userId: "",
      // 资源浏览数据
      resourceData: {},
      // 当前用户评论内容
      myContent: "",
      // 评论总数目
      commentTotal: "",
      // 评论总数据
      commentData: [],

      // 文本是否隐藏
      textActive: true,
      // 控制评论组件刷新
      random: 0,
      // 当前用户头像url
      photo_url: "",
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
    };
  },
  created() {
    this.init();
  },
  methods: {
    onCommentTotal(val) {
      this.commentTotal = val;
    },
    // 初始化函数
    init() {
      this.activityId = this.$route.query.activityId;
      this.id = this.$route.query.id;
      this.is_from = this.$route.query.is_from || 1;
      this.username = window.localStorage.getItem("user_name");
      this.userId = window.localStorage.getItem("id");
      this.photo_url = window.localStorage.getItem("photo_url") || "";
      this.getResourceData();
    },
    //请求后台获取此环节数据
    getResourceData() {
      if (this.is_from == 1) {
        this.$axios
          .get(`activity/${this.activityId}/activityLink/${this.id}`)
          .then((res) => {
            this.resourceData = res.data.data;
          });
      } else {
        this.$axios
          .get(`TeachingStudioactivity/${this.activityId}/activityLink/${this.id}`)
          .then((res) => {
            this.resourceData = res.data.data;
          });
      }
    },
    // 请求后台发布评论函数
    releaseComment() {
      if (!this.myContent.trim()) {
        return this.$message.warning("发布内容不能为空");
      }
      if (this.resourceData.status == "2") {
        var obj = {
          activity_id: this.activityId,
          user_id: this.userId,
          content: this.myContent,
          link_id: this.id,
        };
        if (this.is_from == 1) {
          this.$axios.post("ActivityReply/insertComment", obj).then((res) => {
            this.$message.success("发布成功");
            this.random++;
            this.myContent = "";
            // this.$axios
            //   .post(`activity/${this.activityId}/activityLink/${this.id}`)
            //   .then((res) => {
            //     this.getResourceData();
            //   });
          });
        } else {
          obj.teaching_studio_id = localStorage.getItem("studioId");
          this.$axios
            .post("TeachingStudioActivityReply/insertComment", obj)
            .then((res) => {
              this.$message.success("发布成功");
              this.random++;
              this.myContent = "";
              //   this.$axios
              //     .post(`activity/${this.activityId}/activityLink/${this.id}`)
              //     .then((res) => {
              //       this.getResourceData();
              //     });
            });
        }
      } else if (this.resourceData.status == "1") {
        this.$message.warning("活动环节未开始");
      } else if (this.resourceData.status == "3") {
        this.$message.warning("活动环节已结束");
      }
    },

    // 文本显示与隐藏函数
    showText() {
      if (this.textActive == true) {
        this.textActive = false;
      } else if (this.textActive == false) {
        this.textActive = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
.discussbrowsing {
  .hengxian {
    width: 100%;
    height: 1px;
    background: #ececec;
  }
  .main_content {
    .option_box_div {
      margin-left: auto;
      font-size: 16px;
      color: #333333;
      /deep/.el-input__inner {
        background-color: #f4fbff;
      }
    }
    .five {
      color: #3489fe;
      margin-bottom: 36px;
      span {
        margin-right: 11px;
      }
    }
    .six {
      width: 985px;
      height: 112px;
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .images {
        margin-left: 33px;
        margin-right: 27px;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .six_word {
        margin-right: 261px;
      }
      .six_one {
        width: 120px;
        height: 34px;
        background: #f7f7f7;
        border: 1px solid #ececec;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
      }
      .six_two {
        width: 120px;
        height: 34px;
        background: #3489fe;
        border: 1px solid #ececec;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
      }
    }
    .seven {
      display: flex;
      justify-content: space-between;
      margin-bottom: 37px;

      .seven_left {
        display: flex;
        flex-direction: column;
        align-items: center;
        .images {
          margin-bottom: 13px;
          width: 50px;
          height: 50px;
          overflow: hidden;
          border-radius: 50%;
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
      .seven_right {
        flex: 1;
        overflow: hidden;
        border: 1px solid #ececec;
        border-radius: 4px;
        padding: 20px;
        margin-left: 25px;
        .release {
          width: 92px;
          height: 34px;
          background: #fa8c15;
          border-radius: 4px;
          font-weight: 700;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          float: right;
        }
        /deep/ .el-textarea__inner {
          height: 80px;
          padding: 0;
          border: none;
        }
      }
    }
    .eight {
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 36px;
    }
    .nine {
      .comment_block {
        margin-right: 32px;
        margin-bottom: 40px;
        .block_one {
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          .images {
            margin-right: 19px;
            img {
              width: 50px;
              height: 50px;
            }
          }
          .one_word {
            font-size: 14px;
            p:nth-child(1) {
              color: #fa8c15;
              margin-bottom: 5px;
            }
            p:nth-child(2) {
              color: #9a9ea5;
            }
          }
        }
        .block_two {
          margin-left: 69px;
          margin-bottom: 21px;
          p {
            line-height: 30px;
          }
        }
        .block_three {
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          color: #9a9ea5;
          margin-bottom: 26px;
          .three_one {
            margin-right: 26px;
          }
          i {
            margin-right: 6px;
          }
        }
        .block_four {
          display: flex;
          justify-content: flex-end;
          .reply_block {
            width: 858px;
            // height: 542px;
            background: #f7f7f7;
            border-radius: 8px;
            padding: 30px 27px 34px 30px;
            .reply_block_one {
              .reply_one {
                display: flex;
                align-items: flex-end;
                margin-bottom: 19px;
                .images {
                  margin-right: 14px;
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  overflow: hidden;
                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
                .reply_one_word {
                  font-size: 14px;
                  p:nth-child(1) {
                    margin-bottom: 5px;
                    span:nth-child(odd) {
                      color: #fa8c15;
                    }
                    span:nth-child(2) {
                      margin: 0px 10px;
                    }
                  }
                  p:nth-child(2) {
                    color: #9a9ea5;
                  }
                }
              }
              .reply_two {
                margin-left: 64px;
                margin-bottom: 17px;
                p {
                  line-height: 30px;
                }
              }
              .reply_three {
                display: flex;
                justify-content: flex-end;
                font-size: 14px;
                color: #9a9ea5;
                margin-bottom: 26px;
                .three_one {
                  margin-right: 26px;
                }
                i {
                  margin-right: 6px;
                }
              }
              .reply_four {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 30px;
                .reply_four_hengxian {
                  width: 794px;
                  // height: 1px;
                  border-top: 1px dashed #ececec;
                }
              }
              .reply_five {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 25px;
                .reply_five_block {
                  width: 792px;
                  height: 100px;
                  background: #ffffff;
                  border-radius: 8px;
                  padding-top: 10px;
                  .release_block {
                    width: 92px;
                    height: 36px;
                    background: #fa8c15;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ffffff;
                    float: right;
                    margin-right: 20px;
                  }
                  /deep/ .el-textarea__inner {
                    border: none;
                  }
                }
              }
            }
            .reply_block_one:last-child {
              .reply_four {
                margin-bottom: 0px;
                .reply_four_hengxian {
                  border-top: 0px;
                }
              }
            }
          }
        }
        .block_five {
          width: 914px;
          height: 1px;
          border-top: 1px dashed #ececec;
          margin-top: 26px;
          float: right;
        }
        .comment_block_reply {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 25px;
          .block {
            width: 914px;
            height: 100px;
            background: #ffffff;
            border: 1px solid #ececec;
            border-radius: 8px;
            padding-top: 10px;
            .release {
              width: 92px;
              height: 36px;
              background: #fa8c15;
              border-radius: 4px;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              margin-right: 20px;
            }
            /deep/ .el-textarea__inner {
              border: none;
            }
          }
        }
      }
      .comment_block:last-child {
        .block_five {
          border-top: 0px;
          margin-top: 0px;
        }
      }
    }
  }
  .new_comment {
    margin-right: 17px;
  }
}
</style>
