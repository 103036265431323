<template>
  <div class="option_box_div" v-if="status != 3">
    <el-button @click="endButton()" type="primary" plain>{{status == 1 ? "开始活动" : status == 2 ? "结束此环节" : ""}}</el-button>
  </div>
</template>

<script>
import * as researchApi from "@/api/researchApi.js";
export default {
  props: {
    id: {
      type: Number | String,
      default: 1,
    },
    is_from: {
      type: Number | String,
      default: 1,
    },
    status: {
      type: Number | String,
      default: 0,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    //结束按钮
    endButton() {
      let text = "";
      if (this.status == 1) {
        text = "此操作将开始活动环节, 是否继续?";
      }
      if (this.status == 2) {
        text = "此操作将结束活动环节, 是否继续?";
      }
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleStatusChaneg();
        })
        .catch(() => {});
    },
    //状态变更
    async handleStatusChaneg() {
      try {
        let reqObj = {
          status: this.status == 1 ? 2 : this.status == 2 ? 3 : "",
          id: this.id,
          is_from: this.is_from,
        };
        if (this.is_from == 1) {
          await researchApi.get_resource_activitylink_update_status(reqObj);
        } else {
          await researchApi.get_resource_TeachingStudioactivity_update_status(reqObj);
        }
        this.$message.success("操作成功");
        this.$emit("success", this.status + 1);
      } catch (error) {
        console.log(error, "【error】");
      }
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped></style>
